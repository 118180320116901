import axios from 'axios';
const axiosInstance = axios.create();
let history = null;

export const setAxiosHistory = (_history) => { history = _history }

axiosInstance.interceptors.request.use(
    (request) => {
      try{
        const { tenant_id } = JSON.parse(localStorage.getItem('userContext')) || {};
        const JWTToken = localStorage.getItem('userJWT') || '';

        if(!Number.isInteger(tenant_id) || !JWTToken)
        {
          history.push('/app/error', { error: 'Refresh your window or Try logging back in' })
          throw Promise.reject("x-tenant-context or JWTToken not found");
        }

        
        if(request?.params?.user_id){
          request.headers['user_id'] = request?.params?.user_id;
          request.params = null;
        }
        
        request.headers['x-tenant-context'] = tenant_id;
        request.headers['Authorization'] = `Bearer ${JWTToken}`;
        request.headers['Content-Type'] = 'application/json';
        
        return request;
      }catch(error){
          throw Promise.reject(error);
      }
    }, 
    function(error) {
      return Promise.reject(error);
    }
  );

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    try{
      const { status, data } = error?.response || {};
      const { response } = data || {};
      const responseError = (response?.statusCode ? response?.message : data) || 'Something went wrong';
      if(history){
        if( status === 404 )
          history.push('/404')
        else if( status === 500 )
          history.push('/app/error', { error: 'Something went wrong' })
        else
          history.push('/app/error', { error: responseError })
        return Promise.reject(responseError);
      }
    } catch(e){
      history.push('/app/error', { error: 'Something went wrong' });
      return Promise.reject('Something went wrong')
    }
  }

);

export default axiosInstance;
